import React from "react";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";

import Product from "./Product";

const IMAGE_URL = "https://d2sztxeooqpvky.cloudfront.net";

const MondayInTheInbox = () => {
  const productName = "monday in the Inbox";
  const productLogoUrl =
    "https://d2sztxeooqpvky.cloudfront.net/monday_logo.png";

  const Content = () => {
    return (
      <Styled>
        <Typography variant="h3" gutterBottom id="top-of-page">
          Contents
        </Typography>
        <ul>
          <Typography variant="body1">
            <li>
              <a href="#installation">Installation</a>
            </li>
            <li>
              <a href="#usage">Usage</a>
            </li>
          </Typography>
          <ul>
            <Typography variant="body1">
              <li>
                <a href="#create-monday-task">Create a new monday.com task</a>
              </li>
              <li>
                <a href="#link-monday-task">
                  {" "}
                  Link an existing monday.com task
                </a>
              </li>
              <li>
                <a href="#unlink-monday-task">Unlinking a task</a>
              </li>
              <li>
                <a href="#comment-on-monday-task">Commenting on a task</a>
              </li>
            </Typography>
          </ul>
          <Typography variant="body1">
            <li id="installation">
              <a href="#pricing">Pricing</a>
            </li>
          </Typography>
        </ul>
        <Typography variant="h3" gutterBottom>
          Installation
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. Visit the{" "}
          <a
            href="https://app.intercom.com/a/apps/_/appstore?app_package_code=monday-in-the-inbox"
            target="_blank"
            rel="noopener noreferrer"
          >
            Intercom app store
          </a>{" "}
          and install the monday in the Inbox app
        </Typography>
        <Typography variant="body1" gutterBottom>
          As part of the intstall flow you will be asked to authorize the app to
          access your Intercom and monday.com accounts. We only ask for
          permissions strictly needed for the app functionality.
        </Typography>
        <div className="border-left">
          <Typography variant="body1" gutterBottom>
            ℹ️ We recommend creating a dedicated monday user and Intercom user
            for the app and install it using this user. This will give various
            benefits, including:
          </Typography>
          <ul>
            <Typography variant="body1">
              <li>
                monday.com tasks created via the app will have a generic author.
              </li>
              <li>You can give the appropriate permissions to these users.</li>
              <li>
                Notes posted in Intercom will have a generic author and will
                appear as new notes for you
              </li>
            </Typography>
          </ul>
        </div>
        <Typography variant="body1" gutterBottom>
          2. First you will be asked to authorize the app’s access to your
          Intercom account:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-intercom-auth.png"
          alt="Intercom authorization"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          3. After clicking “Authorize” you will be redirected to monday.com and
          asked to install the Intercom integration in your monday.com
          workspaces. Ensure the correct workspace is selected in the top right
          of the window:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-monday-permissions-reviewed.png"
          alt="monday install permission"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          4. After clicking “Install” you will be redirected to your monday.com
          account, where you should see that the Intercom Integration app is
          installed on your account:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-manage-apps.webp"
          alt="monday manage apps"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          5. Go back to your Intercom Inbox and while looking at a conversation,
          add monday.com in the Inbox to the sidebar by clicking “Edit apps” and
          selecting the pin next to the app’s name:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-add-to-sidebar.png"
          alt="Add monday to Inbox sidebar"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          6. Expand monday.com in the Inbox in the sidebar and click the link to
          authorize the app to access your monday.com account:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-auth-in-sidebar.png"
          alt="Authorize monday from sidebar"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          7. You will be shown monday.com’s authorization screen. Click
          “Authorize”:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-authorize-monday-reviewed.png"
          alt="Authorize monday"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          8. You will be taken back to your Intercom Inbox. Select a
          conversation and you should now be able to create monday.com tasks
          right from the sidebar:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-home-empty.png"
          alt="monday sidebar view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          If you have any issues with the above, please contact us at:{" "}
          <a href="mailto:support@thackd.com">support@thackd.com</a>
        </Typography>
        <Typography variant="body1" gutterBottom id="usage">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h3" gutterBottom>
          Usage
        </Typography>

        <Typography variant="body1">
          The app gives you 2 main options when viewing a conversation in
          Intercom:
        </Typography>
        <ol>
          <Typography variant="body1">
            <li>
              <a href="#create-monday-task">Create a new task</a>
            </li>
            <li>
              <a href="#link-monday-task"> Link an existing task</a>
            </li>
          </Typography>
        </ol>
        <Typography variant="body1">
          You can also <a href="#unlink-monday-task">unlink tasks</a> and{" "}
          <a href="#comment-on-monday-task">comment on tasks</a> that you have
          created or linked from the conversation.
        </Typography>
        <Typography variant="body1" gutterBottom id="create-monday-task">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          1. Create a new monday task
        </Typography>
        <Typography variant="body1" gutterBottom>
          This will create a new task on the specified project in monday and
          link the Intercom conversation and task. It will also post a link to
          the task in a note on the Intercom conversation and a link to the
          Intercom conversation on the monday task.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Additionally, if you install the{" "}
          <a
            href="https://monday.com/marketplace/listing/10000525/intercom"
            target="_blank"
            rel="noopener noreferrer"
          >
            monday.com companion app
          </a>
          , any notes posted on the monday task or changes in its status will be
          posted as notes on the Intercom conversation.
        </Typography>

        <Typography variant="body1" gutterBottom>
          1. Select “Create a new task”:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-home-empty.png"
          alt="monday in the sidebar"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          2. Search for the monday board you wish to create the new task in by
          entering ID or the full or partial board name into the text box and
          clicking the button:
        </Typography>
        <div className="border-left">
          <Typography variant="body1" gutterBottom>
            ℹ️ If you have lot of boards (&gt;100) in your monday.com workspace
            or if the search by name is taking a long time, we recommend
            searching by ID. You can find the board ID in the URL when you are
            viewing the board in monday.com.
          </Typography>
          <Typography variant="body1" gutterBottom>
            For example, in the URL:
            https://your-team.monday.com/boards/123456789 the ID is 123456789
          </Typography>
          <Typography variant="body1" gutterBottom>
            Once you have used a board once, it will appear under "Recently used
            boards" when you create your next task.
          </Typography>
        </div>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-search-board.png"
          alt="Search board"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          3. Select the board from the list of results:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-select-board.png"
          alt="Select board"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          4. If relevant, select a group within the board to create the task in
        </Typography>
        <Typography variant="body1" gutterBottom>
          5. Specify a name and description for your task. Finally, click
          “Create monday task”:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-create-task.png"
          alt="Create task view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          6. A task will be created in the linked monday account and a note is
          added to the Intercom conversation with a link to the task. The note
          is not visible to the customer.
        </Typography>
        <Typography variant="body1" gutterBottom>
          The next time you visit the conversation you will also see a link to
          the task in the sidebar under “Linked tasks”:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-home-linked.png"
          alt="Linked project view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          In the task updates in monday will be the description you entered plus
          the user who created it, information about the user and company
          associated with the conversation the task was created from and the
          conversation attributes:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-task-view.png"
          alt="monday task view"
          className="image"
        />
        <Typography variant="body1" gutterBottom id="link-monday-task">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          2. Link an existing monday task
        </Typography>
        <div className="border-left">
          <Typography variant="body1" gutterBottom>
            ℹ️ This feature is only available with a paid subscription. See{" "}
            <a href="#pricing">pricing</a> for more information.
          </Typography>
        </div>
        <Typography variant="body1" gutterBottom>
          This will link the Intercom conversation you are looking at to an
          existing monday task, post a link to the task in a note on the
          Intercom conversation and a link to, and details of, the Intercom
          conversation on the monday task.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Additionally, any updates posted on the monday task or changes in its
          status will be posted as notes on the Intercom conversation. You will
          also be able to post comments on the monday task from the Intercom
          conversation.
        </Typography>

        <Typography variant="body1" gutterBottom>
          1. Select “Link an existing task”:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-home-empty.png"
          alt="Linked project view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          2. Search for a board by full or partial name e.g. “user feedback
          board”:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-select-board-to-link.png"
          alt="Linked project view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          3. Search for a task by full or partial name e.g. “user creation bug”:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-link-task.png"
          alt="Linked project view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          and select it from the results:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-select-task-to-link.png"
          alt="Linked project view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          4. The task and conversation are now linked.
        </Typography>
        <Typography variant="body1" gutterBottom>
          The next time you visit the conversation the task name will be listed
          under “Linked tasks” in the monday in the Inbox sidebar app.
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-home-linked-2.png"
          alt="Linked project view"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          Linked tasks are shown on the conversation in the right panel. You may
          need to refresh the conversation before it appears.
        </Typography>
        <Typography variant="body1" gutterBottom id="unlink-monday-task">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Unlinking a task
        </Typography>
        <Typography variant="body1" gutterBottom>
          The links between an Intercom conversation and a monday task can be
          removed if you no longer want updates on the monday task to be posted
          to the Intercom conversation.
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. Go to the relevant conversation in the Intercom Inbox
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. In the monday in the Inbox sidebar app, find the task you want to
          unlink in the “Linked tasks” list and click it
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. Click "Unlink" in the task view:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-task-home.png"
          alt="Task home"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          4. A confirmation screen is shown. You may need to refresh the
          Intercom conversation for the linked task to disappear from the
          “Linked tasks” list.
        </Typography>
        <Typography variant="body1" gutterBottom id="comment-on-monday-task">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Commenting on a task
        </Typography>
        <Typography variant="body1" gutterBottom>
          You can post comments on a monday task from the Intercom conversation.
          This avoids your agents needing to access monday to reply to questions
          on the monday task or to add further information to the task.
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. Go to the relevant conversation in the Intercom Inbox
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. In the monday in the Inbox sidebar app, find the task you want to
          comment on in the “Linked tasks” list and click it
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. Click "Comment" in the task view:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-post-comment.png"
          alt="Post comment"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          4. Enter your comment and click "Post comment"
        </Typography>
        <Typography variant="body1" gutterBottom>
          The comment will be posted to the monday task including the details of
          the Intercom user who posted it:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/monday/monday-comment-added.png"
          alt="Comment added to monday task"
          className="image"
        />
        <Typography variant="body1" gutterBottom id="pricing">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h3" gutterBottom>
          Pricing
        </Typography>
        <Typography variant="body1" gutterBottom>
          The monday in the Inbox app is currently free to use, but the features
          below require a paid subscription:
        </Typography>

        <ul>
          <Typography variant="body1">
            <li>Linking conversations to tasks</li>
            <li>
              Comments posted on tasks post live updates on the Intercom
              conversation
            </li>
            <li>
              Task status changes posted live on the Intercom conversation
            </li>
          </Typography>
        </ul>

        <Typography variant="body1" gutterBottom>
          These features can be enabled by installing the{" "}
          <a
            href="https://monday.com/marketplace/listing/10000525/intercom"
            target="_blank"
            rel="noopener noreferrer"
          >
            monday.com companion app
          </a>
          .
        </Typography>

        <Typography variant="body1" gutterBottom>
          Pricing is based on the number of monday.com seats. Detailed pricing
          information is available{" "}
          <a
            href="https://monday.com/marketplace/listing/10000525/intercom"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </Typography>

        <Typography variant="body1" gutterBottom>
          <a href="#top-of-page">Back to top</a>
        </Typography>
      </Styled>
    );
  };

  return (
    <Product
      productName={productName}
      productLogoUrl={productLogoUrl}
      Content={Content}
    />
  );
};

const Styled = styled.div`
  max-width: 800px;

  ul ul {
    padding-left: 20px;
  }

  ul p {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 2rem;
  }

  h3 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  h4 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .border-left {
    border-left: 1px solid #000;
    padding-left: 1rem;
  }

  .image {
    max-width: 600px;
    @media screen and (max-width: 600px) {
      max-width: 100%;
    }
    margin-bottom: 2rem;
  }
`;

export default MondayInTheInbox;
